<script setup>
import { useBackend } from "~/composables/Backend";

const route = useRoute();
const searcher = useSearch();
const backend = useBackend();

const page = ref(1);
const fullLoaded = ref(false);

const { data: collection } = await useAsyncData(async () => {

  const response = await backend.getCollection(route.params.slug);

  if(!response || !response.data) {
    await navigateTo("/");
    throw createError({
      statusMessage: "Moved permanently",
      statusCode: 301,
    });
  }

  return response.data;
});

const {data: cars} = await useAsyncData(async () => {
  let cars = await searcher.search(collection.value.search);
  cars = cars.data;
  if (!cars.length || cars.length < 12) fullLoaded.value = true;
  return cars;
})

useHead({
  title: `Vehis.pl - ${collection.value?.meta_title ? collection.value?.meta_title : collection.value?.title} - atrakcyjny leasing samochodów nowych i używanych`,
  meta: [
    {name: 'description', content: `${collection.value?.meta_description ? collection.value?.meta_description : 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}`}
  ],
  link: [
    {name: 'robots', content: collection.value?.robots},
  ]
});

const loadCars = async (page = 1) => {
  let cars = await searcher.search(collection.value.search, page);
  cars = cars.data?.sort((a, b) => a.price > b.price ? 1 : -1);
  if (!cars.length || cars.length < 12) fullLoaded.value = true;
  return cars;
}

const loadMore = async () => {
  page.value++;
  const newCars = await loadCars(page.value);
  if (newCars.length) {
    carsAvailable.value = carsAvailable.value.concat(newCars);
  }
}

const searchResultsCount = ref(0);
const carsAvailable = ref(cars.value);

const field = (index) => collection?.value.blocks[index]?.boxes;
</script>

<template>
  <Container no-paddings>
    <AppTop @searchResultsCountChange="(v) => searchResultsCount = v" :no-banner="!collection?.banner"/>
    <div>
      <LayoutColumns>
        <LayoutMainCarsColumn>
          <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6">
            <MainContentHeader isH1 :counter="searchResultsCount">{{ collection?.h1 ? collection?.h1 : collection?.title }}</MainContentHeader>
          </div>
          <template v-for="car in carsAvailable">
            <CarTile :car="car" />
          </template>
          <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 mb-6">
            <InViewTrigger v-if="carsAvailable.length && !fullLoaded" @enterView="loadMore">
              <EffectSpinLoader />
            </InViewTrigger>
          </div>
          <template v-if="!carsAvailable.length">
            <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 flex justify-center items-center">
              <div class="py-20 font-extralight">
                <div class="pt-6"> Hmmm... nie znaleziono żadnego samochodu.</div>
                <div class="pb-6 text-xs">Zmień zakres wyszukiwania</div>
              </div>
            </div>
          </template>
        </LayoutMainCarsColumn>
        <LayoutSideColumn class="mt-20">
          <FormContact />
        </LayoutSideColumn>
      </LayoutColumns>
    </div>
  </Container>
  <Container v-if="carsAvailable?.length">
    <SeoMoreAboutModels :cars="carsAvailable" :title="collection?.title + ':'" />
  </Container>
  <Container v-if="field(0)?.[0]">
    <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
      <h2 class="mx-auto text-center text-lg font-normal mb-6">{{collection?.h1 ? collection?.h1 : collection?.title }}</h2>
      <div class="grid grid-cols-1 gap-6">
        <SeoContentBlock :data="field(0)[0]" />
      </div>
      <template v-if="field(1)?.[0] || field(1)?.[1] || field(1)?.[2]" >
        <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
        <div class="grid grid-cols-1 md:grid-cols-3 lg:col-span-3 gap-6">
          <SeoContentBlock v-if="field(1)?.[0]" :data="field(1)[0]" />
          <SeoContentBlock v-if="field(1)?.[1]" :data="field(1)[1]" />
          <SeoContentBlock v-if="field(1)?.[2]" :data="field(1)[2]" />
        </div>
      </template>
      <template v-if="field(2)?.[0] || field(2)?.[1]">
        <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <SeoContentBlock v-if="field(2)?.[0]" :data="field(2)[0]" />
          <SeoContentBlock v-if="field(2)?.[1]" :data="field(2)[1]" />
        </div>
    </template>
    <template v-if="field(3)?.[0] || field(3)?.[1] || field(3)?.[2] || field(3)?.[3]">
        <div class="h-0 my-8 mx-auto w-full border-t border-gray-400"></div>
        <div class="grid grid-cols-1 md:grid-cols-4 lg:col-span- gap-6">
          <SeoContentBlock v-if="field(3)?.[0]" :data="field(3)[0]" />
          <SeoContentBlock v-if="field(3)?.[1]" :data="field(3)[1]" />
          <SeoContentBlock v-if="field(3)?.[2]" :data="field(3)[2]" />
          <SeoContentBlock v-if="field(3)?.[3]" :data="field(3)[3]" />
        </div>
    </template>
  </Container>

  <Container with-border-top>
    <FormContact title="W każdym momencie możesz skorzystać z&nbsp;pomocy naszych Doradców" standalone />
  </Container>

  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
